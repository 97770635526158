<template>
  <div class="bgcontent">
    <Header style="background: none">
      <template slot="title">买入</template>
    </Header>
    <div class="buying">
      <div class="tiptxt" v-html="desc"></div>
      <div class="card margin24">
        <div class="payway">
          <div class="top">
            <div class="txt">选择支付方式</div>
            <div class="txt1">支付方式 ></div>
          </div>

          <div class="paytype margin16">
            <div
              :class="payArr.indexOf('2') == -1 ? 'typebox' : 'selectBox'"
              @click="selectPay('2')"
            >
              <img
                class="payimg"
                :src="require('@/assets/images/mine/zhifubao.png')"
              />
              <div class="tip">支付宝</div>
            </div>
            <div  v-if="coin!='USDT'"
              :class="payArr.indexOf('1') == -1 ? 'typebox' : 'selectBox'"
              @click="selectPay('1')"
            >
              <img
                class="payimg"
                :src="require('@/assets/images/mine/usdt.png')"
              />
              <div class="tip">USDT</div>
            </div>
              <div></div>
            <div
              :class="payArr.indexOf('3') == -1 ? 'typebox' : 'selectBox'"
              @click="selectPay('3')"
            >
              <img
                class="payimg"
                :src="require('@/assets/images/mine/card.png')"
              />
              <div class="tip">银行卡</div>
            </div>
          </div>
          <div class="buynum margin16">
            <van-field
              readonly
              class="inputClass"
              v-model="coin"
              placeholder="选择一个资产"
              @click="clickInput"
            />
            <van-field
              class="inputClass"
              v-model="buynum"
              name="buynum"
              placeholder="请输入求购数量"
              autocomplete="off"
            ></van-field>
            <van-field
                    class="inputClass"
                    v-model="buyprice"
                    name="buyprice"
                    placeholder="请输入USD求购单价"
                    autocomplete="off"
                    @keyup="checkPrice()"
            ></van-field>
            <van-field
                    class="inputClass"
                    :value="buyprice>0?buyprice*parseFloat(tradeInfo.cny_to_us)+' CNY':'求购CNY价格'"
                    placeholder="求购CNY价格"
                    autocomplete="off"
                    readonly
            ></van-field>
            <!--<van-field-->
              <!--class="inputClass"-->
              <!--v-model="buymin"-->
              <!--name="buymin"-->
              <!--autocomplete="off"-->
              <!--placeholder="请输入最低购买人民币"-->
            <!--&gt;</van-field>-->
            <!--<van-field-->
              <!--class="inputClass"-->
              <!--v-model="buymax"-->
              <!--name="buymax"-->
              <!--autocomplete="off"-->
              <!--placeholder="请输入最高购买人民币"-->
            <!--&gt;</van-field>-->
            <!--<div class="notice"  v-if="coin!='USDT'">
              注意：挂单价格最高不能超过{{ parseFloat(max_price).toFixed(4) }}USD，最低不能低于{{
              parseFloat(min_price).toFixed(4)
              }}USD
            </div>-->
          </div>
          <div class="btncell margin48">
            <div class="btn" @click="clickSubmit()">确认下单</div>
          </div>
        </div>
      </div>
      <div class="step margin24">
        <div class="stepbox">
          <div class="tipbox">第一步</div>
          <div class="txt margin16">
            1.选择支付方式 <br />
            2.填写购买信息 <br />
            3.挂单购买
          </div>
        </div>
        <div class="stepbox">
          <div class="tipbox">第二步</div>
          <div class="txt margin16">
            1.查看订单记录<br />
            2.找到待付款订单<br />
            3.根据支付方式付款并上传凭证<br />
          </div>
        </div>
      </div>
    </div>
    <van-picker
      class="picker"
      v-if="showPicker"
      show-toolbar
      title="选择资产"
      :columns="columns"
      :default-index="0"
      @cancel="onCancel"
      @confirm="onConfirm"
    />
    <van-dialog
      v-model="showpwd"
      title="确认下单"
      @cancel="pwdShow"
      @confirm="clickhangBuy"
      show-cancel-button
    >
      <van-field
        type="password"
        v-model="password"
        name="安全密码"
        label="安全密码"
        placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { hangBuy, getOptionDesc, tradeStatistics } from "@/request/api";
import { Toast } from "vant";

export default {
  components: {
    Header,
  },
  data() {
    return {
      showPicker: false,
      buynum: "",
      coin: "",
      buyprice: "",
      buymin: "",
      buymax: "",
      columns: ["ATT","USDT"],
      desc: "",
      address: "",
      userInfo: {},
      payArr: [],
      tradeInfo: {},
      max_price: 0,
      min_price: 0,
      showpwd: false,
      password: "",
    };
  },
  activated() {
      this.coin = this.$route.query.coin;
      // this.buyprice = "";
      // if(this.coin=='USDT'){
      //     this.buyprice =1;
      // }
    this.buynum = "";
    this.buymin = "";
    this.buymax = "";
    this.payArr = [];
    this.address = sessionStorage.getItem("address");
    this.userInfo = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.getOptionDesc();
    this.gettradeStatistics();
  },
  methods: {
    pwdShow() {
      this.showpwd = false;
    },
    checkPrice() {
      if (this.buyprice <= 0) {
        this.buyprice = 0;
      }
        if (this.coin == "ATT") {
            this.buyprice =this.toSecond(this.tradeInfo.et_price,4);
        }
    },
    gettradeStatistics() {
      const params = {
        coin_symbol: this.coin,
        // address: this.$store.state.user.userInfo.address,
      };
      tradeStatistics(params).then((res) => {
        this.tradeInfo = res.data;
        if (this.coin == "ATT") {
          this.max_price = res.data.trade_wave.attmax;
          this.min_price = res.data.trade_wave.attmin;
           this.buyprice = this.toSecond(res.data.et_price,4);
        } else if (this.coin == "EPT") {
          this.max_price = res.data.trade_wave.eptmax;
          this.min_price = res.data.trade_wave.eptmin;
        } else if (this.coin == "ARR") {
          this.max_price = res.data.trade_wave.arrmax;
          this.min_price = res.data.trade_wave.arrmin;
        }
        else if(this.coin == "USDT") {
            this.max_price = 0;
            this.min_price = 0;
        }
      });
    },
      toSecond(value,i){
          return parseFloat(value).toFixed(i)
      },
    getOptionDesc() {
      const params = {
        name: "trade_desc",
        // address: this.$store.state.user.userInfo.address,
      };
      getOptionDesc(params).then((res) => {
        this.desc = res.data.value_zh;
      });
    },
    clickhangBuy() {
      let _this = this;
      if (!this.password) {
        Toast("请输入密码");
        return;
      }
      const params = {
        coin_symbol: this.coin,
        address: this.address,
        pay_code: this.password,
        buy_pay_type: this.payArr.join(","),
        amount: this.buynum,
        price: this.buyprice,
        min_price: this.buymin,
        max_price: this.buymax,
      };
      hangBuy(params).then((res) => {
        if (res.code == 200) {
          this.buynum = "";
          this.buyprice = "";
          this.buymin = "";
          this.buymax = "";
          this.payArr = [];
          Toast("挂买成功");
          setTimeout(function () {
            _this.$router.push({
              path: "/task/hanglog",
              query: {
                coin: _this.coin,
              },
            });
          }, 2500);
        } else {
          Toast(res.msg);
        }
      });
    },
    onCancel() {
      this.showPicker = false;
    },
    clickInput() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.showPicker = false;
      this.inputtopup = value;
      this.coin = value;
      this.gettradeStatistics();
      if( this.coin=='ATT'){
          this.buyprice =this.toSecond(this.tradeInfo.et_price,4);
      }else{
          this.buyprice =0;
      }
    },
    clickSubmit() {
      let _this = this;
      if (!this.address) {
        Toast("请使用币安智能链链接");
        return;
      }
      if (this.address && this.userInfo.has_paypwd != 1) {
        Toast("请先完善资料,设置安全密码");
        setTimeout(function () {
          _this.$router.push({
            path: "/home/mine",
          });
        });
        return;
      }
      if (this.coin.length === 0) {
        Toast("请选择资产");
        return;
      }
      if (this.buynum.length === 0) {
        Toast("请输入求购数量");
        return;
      }
      if (this.buyprice.length === 0) {
        Toast("请输入求购价格");
        return;
      }
      // if (this.buyprice.length === 0) {
      //   Toast("请输入最低购买人民币");
      //   return;
      // }
      // if (this.buyprice.length === 0) {
      //   Toast("请输入最高购买人民币");
      //   return;
      // }
      if (this.payArr.length === 0) {
        Toast("请选择支付方式");
        return;
      }
      if (this.coin == "ATT" && this.buynum < 2) {
        Toast("ATT最低挂单数量为2");
        return false;
      }
      // if (this.buyprice > this.max_price || this.buyprice < this.min_price) {
      //   Toast("挂单价格不在区间段");
      //   return false;
      // }
      // 判断是否在交易时间
      var nowTime = new Date().getTime() / 1000;
    /*  if (
        nowTime > parseInt(this.tradeInfo.endTimestamp) ||
        nowTime < parseInt(this.tradeInfo.startTimestamp)
      ) {
        Toast("今日交易时间段未开启或已结束");
        return false;
      }*/
      this.showpwd = true;
    },
    selectPay(value) {
      let index = this.payArr.indexOf(value);
      if (index === -1) {
        this.payArr.push(value);
      } else {
        this.payArr.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picker {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.margin24 {
  margin-top: 24px;
}

.margin48 {
  margin-top: 48px;
}

.margin32 {
  margin-top: 32px;
}

.margin16 {
  margin-top: 16px;
}

.bgcontent {
  background: rgba(13, 25, 18, 1);
  min-height: 100vh;
  .buying {
    padding: 32px;
    .tiptxt {
      opacity: 1;
      color: rgba(139, 166, 149, 1);
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      line-height: 44px;
      font-family: "PingFang SC";
    }
    .card {
      padding: 24px;
      width: 686px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(16, 43, 29, 1);

      .payway {
        .top {
          display: flex;
          justify-content: space-between;
          .txt {
            color: rgba(214, 255, 227, 1);
            font-size: 32px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
          }
          .txt1 {
            color: rgba(39, 204, 127, 1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
          }
        }

        .paytype {
          display: flex;
          justify-content: space-around;
          /*justify-content: space-between;*/
          .typebox {
            width: 188px;
            height: 72px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(139, 166, 149, 1);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .payimg {
              width: 40px;
              height: 40px;
            }
            .tip {
              color: rgba(139, 166, 149, 1);
              font-size: 32px;
              font-weight: 700;
              font-family: "PingFang SC";
            }
          }
          .selectBox {
            width: 188px;
            height: 72px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(39, 204, 127, 1);
            background: rgba(39, 204, 127, 0.2);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .payimg {
              width: 40px;
              height: 40px;
            }
            .tip {
              color: rgba(39, 204, 127, 1);
              font-size: 32px;
              font-weight: 700;
              font-family: "PingFang SC";
            }
          }
        }
        .buynum {
          .inputClass {
            background: #29332d;
            ::v-deep .van-field__control {
              color: white;
            }
          }
        }
        .btncell {
          display: flex;
          justify-content: center;
          .btn {
            width: 320px;
            height: 88px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(39, 204, 127, 1);
            color: rgba(32, 40, 35, 1);
            font-size: 36px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 88px;
          }
        }
        .tip1 {
          color: rgba(139, 166, 149, 1);
          font-size: 28px;
          font-weight: 700;
          text-align: center;
        }
        .notice {
          margin-top: 8px;
          color: rgba(39, 199, 204, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 36px;
        }
      }
    }

    .step {
      display: flex;
      justify-content: space-between;
      .stepbox {
        width: 328px;
        height: 284px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32, 40, 35, 1);
        padding: 24px;
        .tipbox {
          width: 104px;
          height: 44px;
          border-radius: 8px;
          opacity: 1;
          border: 2px solid rgba(39, 199, 204, 1);
          color: rgba(39, 199, 204, 1);
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          line-height: 44px;
        }
        .txt {
          color: rgba(139, 166, 149, 1);
          font-size: 24px;
          font-weight: 400;
          line-height: 44px;
        }
      }
    }
  }
}
</style>